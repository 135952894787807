.uploader{
  .moxie-shim{
    top: auto !important;
    bottom: 11px;
    cursor: pointer;
  }
}

.plupload_container{
  min-height: 0 !important;

  .plupload{
    .plupload_header{
      display: none;
    }
  
    .plupload_content{
      position: static;
  
      .plupload_filelist_header{
        display: flex;
  
        div{
          padding: 0;
          width: 20%;
        }
      }
  
      .plupload_filelist{
        li{
          display: flex;
  
          div{
            padding: 0;
            width: 20%;
          }
        }

        .plupload_droptext{
          display: none;
        }
      }

      .plupload_filelist_footer{
        position: static;
        display: flex;
        margin-top: 20px;

        .plupload_file_status, .plupload_file_size{
          text-align: left;
        }

        .plupload_file_name{
          .plupload_buttons{
            float: none;
            
            .plupload_add{
              margin-right: 10px;
            }
          }

          .plupload_upload_status{
            height: 12px;
          }
        }
  
        div{
          padding: 0;
          width: 20%;
        }

        .plupload_clearer{
          display: none;
        }
      }
    }
  }
}