.container {
  .course-info-wrap {
    #myaccordion {
      .course-lesson-item {
        .step-text {
          div {
            display: block !important;
          }
        }
      }
    }
  }
}

.large-title {
  @media (min-width: 1220px) {
    margin-top: 200px !important;
  }
}