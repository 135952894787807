.cookie-consent-container{
  background-color: #262626;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 30px;
  width: 100%;
  display: none;
  z-index: 2000;
  align-items: center;

  a:hover{
    color: white;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }

  p{
    color: #d6d5ba; 
    margin: 0;
    font-size: 14px;
    flex-grow: 1;
    padding-right: 15px;

    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
  }

  div{
    display: flex;
    align-items: center;

    a{
      white-space: nowrap;
    }

    input{
      margin: 0 15px;
      color: white;
      background-color: #4b59c0;
      border-radius: 25px;
      padding: 5px 10px;
      border: none;
    }

    i{
      color: white;
      font-size: 22px;
      cursor: pointer;
    }
  }
}