.checkbox-label-container{
    margin-bottom: 15px;

    input[type='checkbox']{
        display: inline-block;
    }
}

#form-privacy-policy{
    border-top: 1px solid #ebebeb;

    input{
      float: none;
    }
}

.terms-gdpr{
    margin-bottom: 15px;
    width: 100%;
    padding: 0 15px;
}

.GDPR-data-container{
    display: none;
    
    h2{
      display: block;
      margin-bottom: 0;
    }

    table{
      margin-bottom: 15px;
    }

    .all-user-orders-data-container{
      .order-container-opener{
        padding: 10px;
        background-color: rgb(235, 235, 235);
        cursor: pointer;
      }

      .order-container{
        display: none;
        padding: 0 15px;

        .order{
          display: flex;
          justify-content: space-between;

          &:hover{
            background-color: rgb(235, 235, 235);
          }
        }
      }
    }
}